.print-adhoc {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    padding: 15px 30px;
}

.label-creator {
    width: 180px;
    float: right;
    text-align: left;
}

.print-adhoc h2 {
    font-size: 18px;
    font-weight: 700;
    color: black;
}

.print-adhoc .barcode-size.barcode-1 {
    float: right;
}

.border-style {
    border: none;
    border-top: 0px dashed #0000a0;
    color: #fff;
    background-color: #fff;
    height: 10px;
    width: 100%;
}

.logo-style-print {
    Color: blue;
    font-weight: bold;
    font-size: 30px;
}

.logo-style-print-1 {
    color: red;
    font-weight: bold;
    font-size: 30px;
}

.print-header-title {
    font-size: 17px;
    font-weight: 600;
}

.id {
    position: absolute;
    bottom: 0px;
    left: 55px;
}

.print-adhoc p {
    color: #000000;
    font-weight: 600;
    line-height: 23px;
    font-size: 14px;
    margin-bottom: 2px;
}

.print-header-description {
    font-size: 16px;
    font-weight: 100;
}

.label-container {
    text-align: end;
}

/* div.landscape {
    margin: 0px;
    margin-bottom: -120px;
    padding: 0px;
    border: none;
    border: 5px solid black;
}

div.landscape {
    transform: rotate(450deg) translate(0mm, -220mm) !important;
    transform-origin: 0 0 !important;
}

.Section1 {
    height: fit-content !important;
    border: 5px solid forestgreen;
}

.tabStyle {
    heigh: 17% !important;
    background: red !important;
    display: inline;
} */