.banner {
  /* background-image:url('./assets/bench-accounting-MGaFENpDCsw-unsplash.jpg') ; */
  width: 100%;
  height: 100%;
  /* background-color: #0000007d; */
  z-index: 99999999999;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}


.btn-submit {
  background: #0000a0;
  color: #fff;

}

.btn-generate {
  background: #0000a0;
  color: #fff;
  width: 66px;
  font-size: 13px;
  height: 33px;
  padding: 0px;
}

.btn-submit-track {
  border-radius: 50px;
  background: #0000a0;
  color: #fff;
}

.btn-submit-package {
  border-radius: 50px;
  background: red;
  color: #fff;
}

.label-style {
  color: #444444;
}

.label-description {
  color: #707070;
}

.badge-primary {
  color: #0000A0;
  background-color: #E7E7FE;
}

.email-style {
  color: #0000a0;
}

.description-text {
  color: #0000a0;
  text-decoration: underline;
}

.label-style {
  font-weight: bold;
}

.main-contain-style {
  padding-top: 20px;
  padding-bottom: 50px;
}

.main-contain-title {
  text-align: center;
  padding-bottom: 20px;
  color: #0000a0;
}

.main-contain-description {
  text-align: justify;
  color: darkgray;
}

.btn-login1 {
  color: #fff;
  background: #0000a0;
}

.btn-back {
  color: #0000a0;
}


span.react-tagsinput-tag.error {
  background-color: red;
  border: red;
  color: #fff;
}

.scan-parcel-loader {
  position: absolute;
  top: 30%;
  right: 44%;
}

.loadingDiv {
  opacity: 0.5;
}

.location-info {
  display: block;
  clear: left;
}

.editTag {
  width: 130px;
  background: transparent;
  border: 0px;
  display: inline;
}

.react-tagsinput-tag {
  cursor: pointer;
}

.custom-width {
  width: 85% !important;
}

.fieldCheck {
  font-size: 13px;
}

.refNo-2 {
  position: absolute;
  justify-content: center;
  align-items: center;
  right: 10px;
  z-index: 100px;
  bottom: 7px;
  font-size: 25px;
}

.tooltip-inner {
  background-color: red
}

.tip-tool-color .tooltip-inner {
  background-color: red !important
}

.cross-button {
  color: red;
  position: absolute;
  top: -8px;
  right: 0px;
  font-size: 19px;
}

.text-bind {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.react-tagsinput-input {
  width: 200px !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}

.data-table-extensions {
  padding: 1.7rem 1.2rem !important;
}

/* .rdt_TableCell div {
  white-space: initial !important;
  overflow: hidden !important;
  text-overflow: unset !important;
} */

.hello-uncccc .tooltip-inner {
  background-color: #ccc;
  color: #000;
}

.pop-editdata :hover {
  color: #0000A0 !important;
}

@media (max-width: 1370px) {
  .fieldBox {
    display: inline-table;
    margin-bottom: 4px;
  }
}

@media (min-width: 570px) {
  .vertical-timeline-element-content {
    min-width: 750px !important;
  }
}

@media (max-width: 769px) {
  .head-label {
    font-size: 15px;
  }
}

@media (max-width: 420px) {
  .head-label {
    font-size: 12px;
  }
}
.user-table-page .pagination li {
  padding: 0 !important;
  border: none !important;
}
.inputTable-padding .data-table-extensions {
  padding: 0 1.2rem !important;
}
.error-display {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.react-tel-input .form-control {
  width: 100% !important;
}
.invalid-number-message {
  display: none;
}
.intl-tel-input {
  width: 100% !important;
}

.input-group>.custom-select:not(:last-child),
.input-group>.form-control:not(:last-child) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.intl-tel-input.allow-dropdown .selected-flag {
  border-right: 1px solid #ccc;
}