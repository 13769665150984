@font-face {
  font-family: RobotoBold;
  src: url("../../assets/font/Roboto-Bold.ttf");
}

@font-face {
  font-family: RobotoMedium;
  src: url("../../assets/font/Roboto-Medium.ttf");
}

@font-face {
  font-family: RobotoRegular;
  src: url("../../assets/font/Roboto-Regular.ttf");
}

@font-face {
  font-family: RobotoLight;
  src: url("../../assets/font/Roboto-Light.ttf");
}

body{
  font-family: RobotoRegular;

}

.about-title{
  font-family: RobotoBold;
  padding-bottom: 10px;
  color: #0000ad;

}

.about-description{
font-family: RobotoRegular;
}

.about-contain{
  padding-top: 150px;
}