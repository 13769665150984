.signup-bg-style {
  background-color: #8e1e1a;
  width: 100%;
  height: 100%;
  color: #fff;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 120px;
}



.btn-secondary:hover {
  background-color: #0000a0;
  border-color: #0000a0;
  color: #fff;
}

.signin-style{
  color: #0000a0;
  padding-left: 5px;
}

.verify-description{
  color: #fff;
}

.PhoneInputInput{
  border: none;
  color: #0000a0;
}
.PhoneInputInput:focus{
outline: none;
}

.p0{
  overflow-x: hidden;
  padding:0px;
}
