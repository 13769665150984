.pagination {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.pagination li {
    cursor: pointer;
    padding: 5px 10px;
    margin: 0 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f5f5f5;
}

.pagination li:hover {
    background-color: #ebebeb;
}

.pagination li.active {
    background-color: #007bff;
    color: #fff;
}