.icon-size {
    width: 20px;
    height: 20px;
}

.custom-filed-select select {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom-filed-select-2 select {
    display: flex;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    /* background-color: #fff; */
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom-filed-select-2 {
    width: 96.4%;
}

.flex-data-select {
    flex-wrap: nowrap;
}

.table-icons {
    width: 23px;
    height: 23px;
}

.adhoc-table span {
    padding: 8px;
    outline: none;
}

.adhoc-table svg {
    outline: none;
}

.dimensionBox {
    width: 95%;
}

.invBox {
    width: calc(100% - 193px)
}

@media (max-width: 1446px) {
    .dimensionBox {
        width: 94%;
    }
}

@media (max-width: 1266px) {
    .dimensionBox {
        width: 93%;
    }
}

@media (max-width: 1137px) {
    .dimensionBox {
        width: 93%;
    }
}

@media (max-width: 914px) {
    .dimensionBox {
        width: 91%;
    }
}

@media (max-width: 726px) {
    .dimensionBox {
        width: 89%;
    }
}

@media (max-width: 636px) {
    .dimensionBox {
        width: 88%;
    }
}

@media (max-width: 582px) {
    .dimensionBox {
        width: 83%;
    }
}

@media (max-width: 480px) {
    .input-group {}

    .track-input {
        width: 100% !important;
    }

    .invBox {
        width: 100%;
    }

    .unique-check {
        margin-top: 5px;
    }

    .unique-check-btn {
        text-align: end;
        display: block;
    }
}

@media (max-width: 429px) {
    .dimensionBox {
        width: 74%;
    }
}

@media (max-width: 575px) {
    .dimensionBox div div {
        margin-bottom: 15px;
    }

    .dimensionBox div:last-child {
        margin-bottom: 0px;
    }
}