.freight-banner-content {
  padding-top: 120px;
}
.freight-banner-content h1 {
  font-weight: 700;
  font-size: 2em;
  color: #ffffff;
  max-width: 690px;
  width: 100%;
  text-align: center;
}

.freight-description-section .freight-intro h2 {
  font-weight: 700;
  color: #000000;
  font-size: 1.5em;
}
.freight-description-section .freight-intro h3 {
  font-weight: 700;
  color: #000000;
  font-size: 1.17em;
}
.freight-description-section .freight-intro p {
  font-size: 14px;
}
.freight-description-section .freight-benefits h2 {
  font-weight: 700;
  font-size: 1.5em;
}
.freight-bg-style {
  height: 350px !important;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
}
.freight-layer {
  background-color: hsl(0deg 0% 0% / 38%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 350px;
}
