.scan-title {
    color: #0000a0;
    /* padding-bottom: 20px; */
    /* padding-top: 20px; */
    font-size: 20px;
    font-weight: 600;
  }
  
  .scan-title-description {
    text-align: center;
    font-size: 15px;
  }
  
  .address-btn{
    white-space: nowrap;
  }
  
  .scan-title-radio{
    color: #0000a0;
    font-size: 20px;
    font-weight: 400;
  }
  
  .scan-input{
    border: 1.3px solid #0000a0 !important;
      border-radius: 5px;
      padding: 3px;
  }
  
  .input-field ::placeholder{
    color: #0000a0 !important;
    padding-left: 8px;
  }
  
  .btn-submit:hover{
    color: #ffffff;
  }
  
  .modal-style{
    overflow: scroll;
      height: 500px;
      overflow-x: hidden;
      width: 100%;
  }
  
  .refNo{
    position: absolute;
    justify-content: center;
    align-items: center;
    right: 33%;
    z-index: 100px;
  }
  
  
  /* input[type=radio] {
    width: 20px;
    height: 20px;
  } */
  
  .radio-item {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    margin: 10px 0 0;
  }
  
  .radio-item input[type='radio'] {
    display: none;
  }
  
  .radio-item label {
    color: #0000a0;
    font-weight: normal;
  }
  
  .radio-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid #0000a0;
    background-color: transparent;
  }
  
  .radio-item input[type=radio]:checked + label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 10px;
    content: " ";
    display: block;
    background: #0000a0;
  }