.nav-tabs {
  border-bottom: 1px solid #0000a0;
}

.nav-tabs .nav-link.active {
  color: #0000a0;
  background-color: #fff;
  border-color: #0000a0 #0000a0 #fff;
}

.search-title-shipment {
  color: blue;
  padding: 15px;
  font-size: 20px;
}

.timeline-progress {
  width: 100%;
  max-width: 70% !important;
  margin: 0 auto;
  position: relative;
  padding: 2em 30px;
}

.eta-progress {
  width: 100%;
  max-width: 70% !important;
  margin: 0 auto;
  position: relative;
}

div.scroll {
  margin: 4px, 4px;
  padding: 4px;
  width: 90%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.vertical-timeline::before {
  content: "";
  position: absolute;
  top: 33px !important;
  left: 101px !important;
  height: 100%;
  width: 36px;
  border-left: 2px dashed #0000a0;
}

.vertical-timeline-element-icon {
  left: 92px !important;
  width: 20px !important;
  height: 20px !important;
  -webkit-box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 0 6px #0000a0, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05) !important;
}

.vertical-timeline-element-date {
  position: absolute;
  width: 100%;
  left: -154px;
  top: -10px;
  font-size: 16px;
  font-size: 1rem;
}

.vertical-timeline-element-content {
  position: relative;
  margin-left: 152px !important;
  background: #fff;
  border-radius: 0.25em;
  padding: 1em;
  -webkit-box-shadow: 0 3px 0 #ddd;
  box-shadow: 0 3px 0 #ddd;
}

.label-style-tracking {
  color: #444444;
  font-weight: bold;
}

.vertical-timeline-element-content {
  background: #0000a0;
  color: #fff;
}

.vertical-timeline-element-content-arrow {
  border-right: 7px solid #0000a0;
}

.loader-style {
  position: absolute;
  left: 50%;
}

.track-card-style {
  width: 70%;
}

.title-main-head-track {
  font-size: 15px;
  color: darkgrey;
  text-transform: uppercase;
  word-break: break-all;
}

.title-description-profile-track {
  font-size: 22px;
  color: #444444;
  word-break: break-all;
}

.heartIcon i {
  animation: heartbeat .5s alternate infinite ease-in;
}

.icons-remove {
  margin-left: 8px;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

.iconHeart {
  position: absolute;
  top: -15px;
  right: -18px;
  color: green;
  /* margin-right: 12px; */
}

@-moz-document url-prefix() {
  .iconHeart {
    position: absolute;
    top: 0px;
    right: -18px;
    color: green;
    /* margin-right: 12px; */
  }
}

.heartStart {
  margin-right: 10px;
}

.newDelvered {
  margin-right: -2px;
}

.deliveredSpan {
  display: flex;
  justify-content: space-between;
  margin-top: -23px
}

.deliveredSpan span:first-child {
  margin-left: -75px;
  margin-top: 10px;
}

.deliveredSpan span:last-child {
  margin-right: -85px;
  margin-top: 10px;
}

.dotStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.dotStyle i:first-child {
  color: green;
  position: absolute;
  left: -13px;
  top: -7px;
  /* z-index: 1; */
}

.dotStyle i:last-child {
  color: red;
  position: absolute;
  right: -15px;
  top: -9px;
  /* z-index: 1; */
}

@media (max-width: 575.98px) {
  .vertical-timeline-element-date {
    position: absolute;
    width: 100%;
    left: 0;
    top: 9px;
    font-size: 16px;
    font-size: 1rem;
  }

  .title-main-head-track {
    font-size: 10px;
  }

  /* .timeline-progress {
    max-width: 100% !important;
  } */

  .title-description-profile-track {
    font-size: 10px;
  }

  .vertical-timeline-element-content {
    background: #fff;
    color: #fff;
  }

  .vertical-timeline-element-content-arrow {
    border-right: 7px solid #fff;
    display: none;
  }

  .vertical-timeline-element-content table.track-table tr td:nth-child(1) {
    width: 50px !important;
  }

  .vertical-timeline::before {
    left: 8px !important;
  }

  .vertical-timeline {
    max-width: 100%;
    width: 100%;
  }

  .vertical-time-style {
    font-size: 11px;
    font-weight: 600;
  }

  .label-style-tracking {
    font-size: 12px;
  }

  .label-description {
    font-size: 12px;
  }

  .vt-style .card {
    background: none !important;
  }

  .vt-style .card-body {
    padding: 0px !important;
  }

  .vt-style .shadow {
    box-shadow: none !important;
  }

  .vertical-timeline-element-content .vertical-timeline-element-date {
    margin-left: 0px !important;
  }

  .vertical-timeline-element-content {
    margin-left: 26px !important;
    box-shadow: none;
    padding: 0;
    width: calc(100% - 42px);
  }

  .vertical-timeline-element-content .track-table {
    width: 100%;
    margin-top: 15px;
    box-shadow: none;
    border: 3px solid #0000a0;
    padding: 0;
  }

  .vertical-timeline-element-content .vertical-timeline-element-date .row {
    display: flex;
    flex-wrap: nowrap;
    margin-top: -25px;
    margin-left: -25px;
  }

  .track-table tr td .pr-5 {
    padding-right: 0 !important;
  }

  .vertical-timeline-element-content .vertical-timeline-element-date .row .col-12 {
    max-width: max-content;
  }

  .vertical-timeline-element-icon {
    left: 5px !important;
    width: 8px !important;
    height: 8px !important;
    box-shadow: 0 0 0 4px #0000a0, inset 0 1px 0 rgba(0, 0, 0, 0.08), 0 1px 0 2px rgba(0, 0, 0, 0.05) !important;
  }

  .track-card-style {
    width: 100%;
  }

  .badge-primary {
    font-size: 10px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .badge-primary {
    font-size: 10px;
  }

  /* .timeline-progress {
    max-width: 100% !important;
  } */

  .label-style-tracking {
    font-size: 10px;
  }

  .label-description {
    font-size: 10px;
  }

  .track-card-style {
    width: 100%;
  }

  .title-main-head-track {
    font-size: 13px;
  }

  .title-description-profile-track {
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .vertical-timeline {
    width: 100%;
  }

  /* .timeline-progress {
    max-width: 100% !important;
  } */

  .badge-primary {
    font-size: 10px;
  }

  .label-style-tracking {
    font-size: 10px;
  }

  .label-description {
    font-size: 10px;
  }

  .track-card-style {
    width: 100%;
  }

  .title-main-head-track {
    font-size: 15px;
  }

  .title-description-profile-track {
    font-size: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .badge-primary {
    font-size: 12px;
  }

  .vertical-timeline {
    width: 100%;
    max-width: 80% !important;
    margin: 0 auto;
    position: relative;
    padding: 2em 0;
  }

  .track-card-style {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .badge-primary {
    font-size: 12px;
  }

  .vertical-timeline {
    width: 100%;
    max-width: 70% !important;
    margin: 0 auto;
    position: relative;
    padding: 2em 0;
  }
}

/* .vertical-timeline-element {
  margin: 4em 2em !important;
} */

/* 
::-webkit-scrollbar {
  width: 20px;
}


::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 

::-webkit-scrollbar-thumb {
  background: red; 
  border-radius: 10px;
}


::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
} */