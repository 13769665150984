.drop-button-table {
    background: transparent !important;
    color: #000 !important;
    border: none !important;
}

.drop-button-table :focus-visible {
    outline: none !important;
    border: none !important;
}


button.drop-button-table.btn.btn-secondary {
    box-shadow: none !important;
}

.menuOfDrop {
    padding: 10px !important;
    width: 165px;
}

.menuOfDropBtn1 {
    background: #28a745 !important;
    font-size: 14px;
}

.menuOfDropBtn2 {
    background: #007bff !important;
    font-size: 14px;
}

.menuOfDropBtn3 {
    background: #dc3545 !important;
    font-size: 14px;
}
.menuOfDropBtn4 {
    background: #E74292 !important;
    font-size: 14px;
}

.menuOfDropBtn5 {
    background: #BB2CD9 !important;
    font-size: 14px;
}

.menuOfDropBtn6 {
    background: #10A881 !important;
    font-size: 14px;
}