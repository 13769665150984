.btn-submit{
    background: #0000a0;
    color: #fff;
    
  }
  
  .btn-generate{
    background: #0000a0;
    color: #fff;
    width: 66px;
    font-size: 13px;
    height: 33px;
    padding: 0px;
  }
  
  .btn-submit-track{
    border-radius: 50px;
    background: #0000a0;
    color: #fff;
  }
  
  .label-style{
    color: #444444;
  }
  
  .label-description{
    color: #707070;
  }
  
  .badge-primary {
    color: #0000A0;
    background-color: #E7E7FE;
  }
  
  .scan-modal-text{
    text-align: center;
      color: #0000a0;
      font-size: 30px;
      padding: 10px;
  }