
.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

/* hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid#fff;
} */

hr {
  border: 0;
  clear:both;
  display:block;
  width: 100%;
  background-color: #f1f1f1;
  height: 1px;
  margin: 0px;
  margin-bottom: 20px;
}


#line {
  width: 100%;
}

@media (max-width: 768px) {
  #sidebar {
      margin-left: -250px;
     
  }
  #sidebar.active {
      margin-left: 0;
  }
}


body {
  font-family: RobotoRegular;
  background: #fafafa;
}



@font-face {
  font-family: RobotoBold;
  src: url('../../assets/font/Roboto-Bold.ttf');
}

@font-face {
  font-family: RobotoMedium;
  src: url('../../assets/font/Roboto-Medium.ttf');
}

@font-face {
  font-family: RobotoRegular;
  src: url('../../assets/font/Roboto-Regular.ttf');
}


@font-face {
  font-family: RobotoLight;
  src: url('../../assets/font/Roboto-Light.ttf');
}



