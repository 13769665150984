.c-header {
  background-color: var(--main-bg-color-container-header);
  color: var(--main-txt-color-container-header);
}

.style {
  padding: 20px;
  height: 100vh !important;
}

.Received-Parcel-Title {
  color: #0000a0;
  font-weight: bold;
}

.title-main-head {
  font-size: 15px;
  color: darkgrey;
  text-transform: uppercase;
  word-break: break-all;
}

.title-description-profile {
  font-size: 22px;
  color: #444444;
  word-break: break-all;
}

.dashboard-profile {
  border-radius: 50px;
  height: 60px;
  width: 60px;
}

.dashboard-card-style {
  border: none;
  border-radius: 20px;
}

.card-style-1 {
  height: 180px !important;
  border: none;
  border-radius: 20px;
}

.help-text-style{
  color: #0000a0;
  font-weight: bold;
}

.icon_size50{
  color: #0000a0;
  font-size: 72px;
}

@media (max-width: 575.98px) {
  .home-card-style .card-style-1 {
    height: 207px !important;
    border: none;
    border-radius: 20px;
    font-size: 14px;
  }
  .title-main-head {
    font-size: 10px;
  }
  .title-description-profile {
    font-size: 10px;
  }
  .dashboard-profile {
    height: 22px;
    width: 22px;
}
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .dashboard-profile {
    height: 35px;
    width: 35px;
}
  .home-card-style .card-style-1 {
    height: 221px !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-profile {
    height: 40px;
    width: 40px;
}
  .home-card-style .card-style-1 {
    height: 221px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .home-card-style .card-style-1 {
    height: 221px !important;
  }
}

@media (min-width: 1200px) {
  .home-card-style .card-style-1 {
    height: 221px !important;
  }
}
