/* @media print {
    @page {
        size: 99.1mm 67.7mm portrait;
        margin: 0;
        padding: 0;
    } */
span.cls_002 {
    font-family: Arial, serif;
    font-size: 24px;
    color: rgb(108, 100, 231);
    font-weight: normal;
    font-style: normal;
    text-decoration: none
}

div.cls_002 {
    font-family: Arial, serif;
    font-size: 15.8px;
    color: rgb(108, 100, 231);
    font-weight: normal;
    font-style: normal;
    text-decoration: none
}

.borderTop {
    border-top: 10px solid #2a3497;
}

.border_002 {
    border-top: 2px solid #d8d8d8;
    margin: 5px -15px;
}

.section1 {

    background-color: #fff;
    margin: 100px 0px;
    height: fit-content !important;

}

.currency-symbol {
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
    width: 100%;
}

.innerContent {
    margin: 20px;
}

span.cls_003 {
    font-family: Arial, serif;
    font-size: 15px;
    color: rgb(102, 102, 102);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    display: block;
}

div.cls_003 {
    font-family: Arial, serif;
    font-size: 8.4px;
    color: rgb(102, 102, 102);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;

}

span.cls_004 {
    font-family: Arial, serif;
    font-size: 30.3px;
    color: rgb(40, 53, 146);
    font-weight: bold;
    font-style: normal;
    text-decoration: none
}

div.cls_004 {
    font-family: Arial, serif;
    font-size: 28.3px;
    color: rgb(40, 53, 146);
    font-weight: bold;
    font-style: normal;
    text-decoration: none
}

span.cls_005 {
    font-family: Arial, serif;
    font-size: 15.5px;
    color: rgb(224, 27, 132);
    font-weight: bold;
    font-style: normal;
    text-decoration: none
}

div.cls_005 {
    font-family: Arial, serif;
    font-size: 10.0px;
    color: rgb(224, 27, 132);
    font-weight: bold;
    font-style: normal;
    text-decoration: none
}

span.cls_006 {
    font-family: Arial, serif;
    font-size: 15px;
    color: rgb(67, 67, 67);
    font-weight: bold;
    font-style: normal;
    text-decoration: none
}

div.cls_006 {
    font-family: Arial, serif;
    font-size: 10.0px;
    color: rgb(67, 67, 67);
    font-weight: bold;
    font-style: normal;
    text-decoration: none
}

span.cls_007 {
    font-family: "Calibri", serif;
    font-size: 15.2px;
    color: rgb(0, 0, 0);
    font-weight: normal;
    font-style: normal;
    text-decoration: none
}

div.cls_007 {
    font-family: "Calibri", serif;
    font-size: 9.2px;
    color: rgb(0, 0, 0);
    font-weight: normal;
    font-style: normal;
    text-decoration: none
}

span.cls_008 {
    font-family: Arial, serif;
    font-size: 15px;
    color: rgb(42, 56, 144);
    font-weight: bold;
    font-style: normal;
    text-decoration: none
}

div.cls_008 {
    font-family: Arial, serif;
    font-size: 10.0px;
    color: rgb(42, 56, 144);
    font-weight: bold;
    font-style: normal;
    text-decoration: none
}

span.cls_009 {
    font-family: Arial, serif;
    font-size: 14.4px;
    color: rgb(153, 153, 153);
    font-weight: normal;
    font-style: normal;
    text-decoration: none
}

div.cls_009 {
    font-family: Arial, serif;
    font-size: 8.4px;
    color: rgb(153, 153, 153);
    font-weight: normal;
    font-style: normal;
    text-decoration: none
}

span.cls_010 {
    font-family: Arial, serif;
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
}

.table_002 {
    width: 100%;
    margin-top: 20px;
}

.border_005 {
    background-color: #f3f3f3;
    border-bottom: 2px solid #d0d0d0;
}

div.cls_010 {
    font-family: Arial, serif;
    font-size: 8.4px;
    color: rgb(0, 0, 0);
    font-weight: normal;
    font-style: normal;
    text-decoration: none
}

span.cls_011 {
    font-family: Arial, serif;
    font-size: 14px;
    color: rgb(42, 56, 144);
    font-weight: normal;
    font-style: normal;
    text-decoration: none
}

div.cls_011 {
    font-family: Arial, serif;
    font-size: 8.4px;
    color: rgb(42, 56, 144);
    font-weight: normal;
    font-style: normal;
    text-decoration: none
}

span.cls_012 {
    font-family: Arial, serif;
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none
}

div.cls_012 {
    font-family: Arial, serif;
    font-size: 8.4px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none
}

span.cls_013 {
    font-family: Arial, serif;
    font-size: 14px;
    color: rgb(102, 102, 102);
    font-weight: bold;
    font-style: normal;
    text-decoration: none
}

.margin_30 {
    margin-bottom: 30px;
}

div.cls_013 {
    font-family: Arial, serif;
    font-size: 8.4px;
    color: rgb(102, 102, 102);
    font-weight: bold;
    font-style: normal;
    text-decoration: none
}

span.cls_014 {
    font-family: Arial, serif;
    font-size: 16.7px;
    color: rgb(224, 27, 132);
    font-weight: bold;
    font-style: normal;
    text-decoration: none
}

div.cls_014 {
    font-family: Arial, serif;
    font-size: 16.7px;
    color: rgb(224, 27, 132);
    font-weight: bold;
    font-style: normal;
    text-decoration: none
}

.margin_005 {
    padding: 0px 60px;
}

td,
th {
    padding: 5px 10px;
}

.print-bill-pdf-view-container {
    overflow-x: auto;
}

.print-bill-pdf-view-container .landscape.print-bill-pdf-view {
    min-width: 900px;
}

table.print-pdf {
    width: 100%;
}

table.print-pdf td {
    border: 1px solid #000000;
}

table.print-pdf td p {
    color: #000000;
    font-size: 12px;
    margin: 0;
    font-family: 'Courier New', Courier, monospace;
    /* font-weight: bold; */
}

table.print-pdf td p b {
    font-size: 16px;
    font-weight: normal
        /* color: aqua; */
        /* padding-left: 3px; */
}

table.print-pdf .w-half {
    width: 48%;
    float: left;
    margin-right: 2%;
}

table.print-pdf.table-noborder {
    width: 350px !important;
}

.company-name p {
    margin: 0;
    font-weight: 600;
    padding: 0;
    color: #000;
}



.tableFooter {
    border-top: 3px solid #000;
    border-bottom: 3px solid #000;
    padding: 20px 0;
    margin-bottom: 15px;
}

.tableFooter h4 {
    font-size: 14px;
    font-weight: 700;
}

.tableFooter p {
    font-size: 12px;
    color: #000000;
    font-family: 'Courier New', Courier, monospace;
    margin: 0;
}

.tableFooter p b {
    font-size: 16px;
    color: #000000;
    /* font-weight: 600; */
    padding-left: 3px;
    font-weight: normal
}

.tableFooter p {
    font-size: 14px;
    color: #000000;
    margin: 0;
}