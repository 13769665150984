:root {
  --main-bg-color-navbar: #ffffff;  
  --main-txt-color-navbar: #fff;
 
}

:root{
  --main-bg-color-sidebar:#fff;
  --main-txt-color: #929aac;
}

:root{
--main-bg-color-container-header:#0000A0;
--main-txt-color-container-header: #fff;
}