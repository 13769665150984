@media (max-width: 575.98px) {
  .add-button {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {

}

.nfc-modal > .modal-header{
  border-bottom: none !important;
}


