.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.wrapper {
  display: flex;
  align-items: stretch;
}

ul .navbar-nav {
  position: fixed;
  overflow: scroll;
  height: calc(100vh - 34px);
  padding-top: 0px;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  min-height: 100vh;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.main-wrapper {
  width: calc(100% - 255px);
  background: "#f7f7f9";
}

.wrapper-full {
  width: 100%;
  background: "#f7f7f9";
}

.sidemenu-wrapper {
  width: 255px;
  display: 'flex';
}

@media (max-width: 768px) {
  #sidebar {
    /* margin-left: 0px;
    z-index: 1; */
    position: fixed;
    margin-left: 0;
    z-index: 9999;
  }

  #sidebar.active {
    margin-left: 0;
  }
}

@font-face {
  font-family: RobotoRegular;
  src: url("../../assets/font/Roboto-Regular.ttf");
}

@font-face {
  font-family: RobotoLight;
  src: url("../../assets/font/Roboto-Light.ttf");
}

body {
  font-family: RobotoRegular;
  background: #fafafa;
  overflow-y: auto !important;
}

p {
  font-family: RobotoLight;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

#sidebar {
  background-color: var(--main-bg-color-sidebar);
  color: var(--main-txt-color);
  transition: all 0.3s;
  /* margin-top: 72px; */
}

#sidebar .sidebar-header {}

#sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #47748b;
}

#sidebar ul p {
  color: #000000;
  padding: 10px;
}

#sidebar ul li a {
  padding: 15px;
  font-size: 15px;
  display: block;
}

#sidebar ul li a:hover {
  color: #0000a0;
  /* background: #e4e5ff; */
  border-top-right-radius: 51px;
  border-bottom-right-radius: 51px;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
  color: #0000a0;
  background: #e4e5ff;
  border-top-right-radius: 51px;
  border-bottom-right-radius: 51px;
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}

.nav-style {
  background-color: var(--main-bg-color-navbar);
  /* background: none; */
}

.btn-style {
  width: 40px;
  height: 40px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 12px;
  text-align: center;
}

.profile-img-style {
  border-radius: 50px;
  width: 30px;
  height: 30px;
}

.dropdown-button {
  border: 0px;
  background: none;
  padding: 0px;
}

.dropdown-button:focus {
  outline: none;
}

/* .btn-secondary:focus {
  color: #fff;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
} */

/* @font-face{
  font-family: 'country';
  src: url('../../assets/font/Countryside-YdKj.ttf');
  font-weight: normal;
  font-style: normal;
} */

.title-style1 {
  font-size: 20px;
  color: var(--main-txt-color-navbar);
  pointer-events: none;
  display: flex;
  justify-content: center;
  padding-left: 10px;
}

@media (max-width: 570px) {
  .main-wrapper {
    width: 100%;
    background: "#f7f7f9";
  }

  .wrapper-full {
    width: 100%;
    background: "#f7f7f9";
  }

  .sidemenu-wrapper {
    width: auto;
    display: 'flex';
  }
}

.header-menu-link {
  color: #0f2aa0 !important
}
.notifiationDropDown h6 {
  font-size: 14px;
  font-weight: 200;
  margin: 0;
}

.notifiationDropDown hr {
  background: #000000;
}