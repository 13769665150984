.nopage-container {
  background-color: gray;
  opacity: 0.8;
}

.img-style-404 {
  height: 100vh;
}

.notfound-title {
  font-size: 122px;
  font-weight: bold;
  color: #dc3545;
}

.notfound-description {
  font-size: 28px;
  width: 35%;
  padding-left: 22px;
  font-weight: bold;
  color: #dc3545;
}

.notfound-contain {
  padding-top: 60px;
}
.notfound-contain1 {
  padding-top: 50px;
}

.notfound-btn {
  color: #fff !important;
  background-color: #0000a0 !important;
  border-color: #0000a0 !important;
}

@media (max-width: 575.98px) {
  .notfound-title {
    font-size: 77px;
  }

  .notfound-description {
    font-size: 19px;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .notfound-title {
    font-size: 77px;
  }

  .notfound-description {
    font-size: 19px;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}
