/* @media print {
    @page {
        size: 99.1mm 67.7mm portrait;
        margin: 0;
        padding: 0;
    } */
    .fullwidth-table{
        width: 100%;
        border: 1px solid black !important;
    }
    .fullwidth-table tr, .fullwidth-table th, .fullwidth-table td{
        border: 1px solid black !important;
        
    }
    .fullwidth-table td {
        font-family:"Calibri Bold",serif;font-size:12.1px;
    }
    .fullwidth-table th {
        font-family:"Calibri Bold",serif;font-size:13.1px;
    }
    .fullwidth-table td span{
        display: block;
    }
    .section2{
        background-color: #fff;
    }
    
    /* } */