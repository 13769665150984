.text-header-title {
  font-size: 12px;
  color: darkgrey;
  text-transform: uppercase;
  word-break: break-all;
}

.text-header {
  font-size: 22px;
  color: #444444;
  word-break: break-all;
}

.profile-image {
  position: relative;
  width: 140px;
  height: 140px;
  margin: 0 auto;
  display: flex;
  background-color: #fafafa;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
}

.profile-edit {
  position: absolute;
  top: 0;
  right: -15px;
  margin-left: 10px;
}

.otp-modal button {
  padding: 0 !important;
  margin: 0 !important;

}

.otp-modal button :focus-visible {
  outline: none !important;
}

.otp-modal button:focus {
  outline: none !important;
}

.input-group-text-otp {
  position: absolute;
  right: 8px;
    bottom: 14px;
}