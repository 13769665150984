@font-face {
  font-family: RobotoLight;
  src: url("../../assets/font/Roboto-Light.ttf");
}

@font-face {
  font-family: RobotoBold;
  src: url("../../assets/font/Roboto-Bold.ttf");
}

.header {
  background: #eeeeee;
  height: 60px;
}

.contact-add {
  /* z-index: 1; */
  position: absolute;
  width: 90%;
  height: 86%;
}

.circle-style {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background: #0000a0;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
  z-index: 9999;
}

.chat-icon {
  width: 30px !important;
  height: 30px !important;
}

.header-1 {
  background: #ffffff;
  height: 46px;
  border-right: 1px solid #fafafa;
}

.profile-img {
  width: 35px;
  height: 35px;
  border-radius: 50px;
}

.pdf-logo-img {
  width: 60px;
  height: 60px;
  border-radius: 1px;
}

.watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-65deg);
  opacity: 0.2;
  font-size: 100px;
  color: #d75151;
  pointer-events: none;
  text-align: center;
  line-height: 100px;
  font-weight: bold;
  white-space: nowrap;
}

.userProfile {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.chat-box-list {
  background: #fff;
  /* height: 100%; */
  height: 68vh;
}

.chat-box {
  background: #e0d9d3;
  height: 100%;
}

.web-bg {
  background-image: linear-gradient(#0000a0 225px, #dfdfdf 0, #dfdfdf 100%);
  height: 100%;
}

.chat-contain-size {
  padding-top: 40px;
}

.chat-main-title {
  font-size: 20px;
  color: #0000a0;
  font-weight: 200;
  align-self: center;
}

.chat-title {
  white-space: nowrap;
  color: #444444;
  font-size: 15px;
}

.chat-description {
  white-space: nowrap;
  color: #cccccc;
  font-size: 15px;
}

.chat-time {
  text-align: right;
  font-size: 15px;
  color: #cccccc;
}

.chat-text {
  word-break: break-all;
}

.notification {
  background: #0000a0;
  border-radius: 26px;
  color: #fff;
  width: 22px;
  height: 22px;
}

.chat-message {
  background: #f1f6fb;
  padding: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: fit-content;
  border-radius: 15px;
  border-bottom-left-radius: 0px;
  height: -webkit-fill-available;
}

.chat-message-1 {
  background: #0000a0;
  color: #fff;
  padding: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: fit-content;
  border-radius: 15px;
  border-bottom-right-radius: 0px;
  height: -webkit-fill-available;
}

.chat-main-title-back-icon {
  font-size: 33px;
  color: #0000a0;
  font-weight: 200;
  align-self: center;
}

.chat-icon-style {
  margin-top: -9px;
}

.history-title {
  white-space: nowrap;
  color: #ff0000;
  font-size: 16px;
  font-family: RobotoBold;
}

.history-title-1 {
  white-space: nowrap;
  color: #2f2f8b;
  font-size: 16px;
  font-family: RobotoBold;
}

.history-description {
  white-space: nowrap;
  color: #ff0000;
  font-size: 15px;
}

.history-description-1 {
  white-space: nowrap;
  color: #707070;
  font-size: 15px;
}

.date-style {
  color: #bcc5d3;
  font-size: 13px;
}

.chat-main-head-text {
  color: #0000a0;
}

.chat-text-style {
  font-size: 12px;
}

.send-icon {
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 0px;
}

/*Conversation*/

.message {
  padding: 20px !important;
  margin: 0 !important;
  /* background: url("w.jpg") no-repeat fixed center; */
  background-size: cover;
  overflow-y: auto;
  border: 1px solid #f7f7f7;
  /* height: calc(100% - 120px); */
  height: 600px;
  background: #fff;
}

.message-previous {
  margin: 0 !important;
  padding: 0 !important;
  height: 0;
  width: 100%;
}

.previous {
  font-size: 15px;
  text-align: center;
  padding: 10px !important;
  cursor: pointer;
}

.previous a {
  text-decoration: none;
  font-weight: 700;
}

.delete-icon {
  font-size: 25px;
}

.overflow-style {
  overflow-x: hidden;
}



.react-contextmenu--visible {
  background-color: #fff !important;
  border-radius: 3px !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16) !important;
  /* padding: 9px 0 !important; */
  z-index: 1;
}

.react-contextmenu-item {
  padding: 10px !important;
}

.bg-start-screen {
  background-color: #f3f3f3;
  width: 100%;
  height: 100%;
}

.pending-title {
  font-size: 12px;
  color: #0000a0;
}

#id_div_1 {
  direction: ltr;
  white-space: wrap;
  /* background: #fff; */
  position: relative;
  border: 1px solid transparent;
  /* padding: 10px 20px 10px 10px; */
}

.remove_link {
  visibility: hidden;
  position: absolute;
  top: 4px;
  right: 5px;
}

#id_div_1:hover .remove_link {
  visibility: visible;
}

.fa-times-circle {
  color: grey;
}

.fa-times-circle:hover {
  color: #cc0033;
}

.icon_size16 {
  font-size: 16px;
}

.chat-text-style {
  overflow-wrap: anywhere;
}

/*Reply*/

.reply {
  height: 60px;
  width: 100%;
  background-color: #f5f1ee;
  padding: 10px 5px 10px 5px !important;
  margin: 0 !important;
  z-index: 1000;
}

.reply-emojis {
  padding: 5px !important;
}

.reply-emojis i {
  text-align: center;
  padding: 5px 5px 5px 5px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-recording {
  padding: 5px !important;
}

.reply-recording i {
  text-align: center;
  padding: 5px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-send {
  padding: 5px !important;
}

.reply-send i {
  text-align: center;
  padding: 5px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-main {
  padding: 2px 5px !important;
}

.reply-main textarea {
  width: 100%;
  resize: none;
  overflow: hidden;
  padding: 5px !important;
  outline: none;
  border: none;
  text-indent: 5px;
  box-shadow: none;
  height: 100%;
  font-size: 16px;
}

.reply-main textarea:focus {
  outline: none;
  border: none;
  text-indent: 5px;
  box-shadow: none;
}

@media screen and (max-width: 700px) {
  .app {
    top: 0;
    height: 100%;
  }

  .heading {
    height: 70px;
    background-color: #009688;
  }

  .fa-2x {
    font-size: 2.3em !important;
  }

  .heading-avatar {
    padding: 0 !important;
  }

  .heading-avatar-icon img {
    height: 50px;
    width: 50px;
  }

  .heading-compose {
    padding: 5px !important;
  }

  .heading-compose i {
    color: #fff;
    cursor: pointer;
  }

  .heading-dot {
    padding: 5px !important;
    margin-left: 10px !important;
  }

  .heading-dot i {
    color: #fff;
    cursor: pointer;
  }

  .sideBar {
    height: calc(100% - 130px);
  }

  .sideBar-body {
    height: 80px;
  }

  .sideBar-avatar {
    text-align: left;
    padding: 0 8px !important;
  }

  .avatar-icon img {
    height: 55px;
    width: 55px;
  }

  .sideBar-main {
    padding: 0 !important;
  }

  .sideBar-main .row {
    padding: 0 !important;
    margin: 0 !important;
  }

  .sideBar-name {
    padding: 10px 5px !important;
  }

  .name-meta {
    font-size: 16px;
    padding: 5% !important;
  }

  .sideBar-time {
    padding: 10px !important;
  }

  .time-meta {
    text-align: right;
    font-size: 14px;
    padding: 4% !important;
    color: rgba(0, 0, 0, 0.4);
    vertical-align: baseline;
  }

  /*Conversation*/
  .conversation {
    padding: 0 !important;
    margin: 0 !important;
    height: 100%;
    /*width: 100%;*/
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    /*overflow-y: auto;*/
  }

  .message {
    /* height: calc(100% - 140px); */
    height: 68vh;
  }

  .reply {
    height: 70px;
  }

  .reply-emojis {
    padding: 5px 0 !important;
  }

  .reply-emojis i {
    padding: 5px 2px !important;
    font-size: 1.8em !important;
  }

  .reply-main {
    padding: 2px 8px !important;
  }

  .reply-main textarea {
    padding: 8px !important;
    font-size: 18px;
  }

  .reply-recording {
    padding: 5px 0 !important;
  }

  .reply-recording i {
    padding: 5px 0 !important;
    font-size: 1.8em !important;
  }

  .reply-send {
    padding: 5px 0 !important;
  }

  .reply-send i {
    padding: 5px 2px 5px 0 !important;
    font-size: 1.8em !important;
  }
}

.start-title {
  font-family: RobotoLight;
}

.close-icon {
  font-size: 20px;
  padding-right: 20px;
  color: #0000a0;
}

.print-btn .dropdown .dropdown-toggle {
  width: 78px;
  display: flex;
}

.print-btn .dropdown .dropdown-toggle::after {
  right: 13px;
}

@media (max-width: 575.98px) {}

@media (min-width: 576px) and (max-width: 767.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {
  .chat-message {
    width: fit-content !important;
  }

  .chat-message-1 {
    width: fit-content !important;
  }

  .chat-box-list {
    height: 660px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .chat-message {
    width: fit-content !important;
  }

  .chat-message-1 {
    width: fit-content !important;
  }

  .chat-box-list {
    height: 660px !important;
  }
}

@media (min-width: 1200px) {
  .chat-box-list {
    height: 674px !important;
  }
}