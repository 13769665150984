.login-bg-style.login-errand {
  height: 100%;
}

ul.login-description-ul {
  list-style-type: disclosure-closed;
  list-style-position: inside;
  margin: 0;
  padding-left: 0;
}

ul.login-description-ul li {
  line-height: 26px;
  margin-bottom: 5px !important;
}

.login-form.requestErrand-form {
  max-width: 900px;
  width: calc(100% - 30px);
  margin: 0 auto;
  padding: 30px 20px;
}

.login-form.requestErrand-form input {
  background: #e4e4e4;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #ccc;
  color: #000;
  font-size: 16px;
  margin: 0 !important;
  padding: 5px 15px;
}

.login-form.requestErrand-form .form-group {
  margin-bottom: 10px;
}

.login-form.requestErrand-form .react-datepicker__input-container {
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  margin: 0 !important;
  border: none !important;
  padding-left: 0 !important;
  height: 40px;
}

.login-form.requestErrand-form .react-datepicker-wrapper {
  height: 40px;
  border: none;
  margin-bottom: 0px;
}

.login-form.requestErrand-form textarea.form-control {
  background: #e4e4e4;
  height: 50px;
  border-radius: 0;
  border: 1px solid #ccc;
  color: #000;
  font-size: 18px;
  margin: 0 !important;
  padding: 15px;
  min-height: 100px;
}

.login-form.requestErrand-form label {
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0;
}

.login-form.requestErrand-form .btn.btn-login.btn {
  font-size: 18px;
  padding: 10px 25px;
  margin-bottom: 15px;
}

.login-form.requestErrand-form .more-info-btn {
  font-size: 18px;
  color: #000000;
  font-weight: 600;
}

.login-form.requestErrand-form .more-info-btn p.text-black {
  font-weight: 600;
  color: #000;
  padding: 8px 0;
  font-family: 'RobotoRegular';
}

.login-form.requestErrand-form .more-info-btn p.text-black a {
  color: #0000a0;
}

.login-form {
  max-width: 550px;
  width: calc(100% - 30px);
  margin: 0 auto;
  padding-top: 120px;
}

.login-bg-style {
  background-color: #8e1e1a;
  width: 100%;
  min-height: 100vh;
  height: auto;
  color: #ffffff;
  padding: 120px 50px 15px 50px;
}

.login-bg-style a img {
  max-width: 250px;
  width: 100%;
}

.btn-secondary:hover {
  background-color: #0000a0;
  border-color: #0000a0;
  color: #ffffff;
}

.text-area-size {
  padding-top: 160px;
  padding-left: 50px;
  padding-right: 50px;
}

.title-style {
  padding-bottom: 50px;
  text-align: center;
}

.btn-login {
  background: #0000a0;
  color: #ffffff;
}

.input-group-text {
  background: none;
}

.icon-style {
  color: #0000a0;
}

.login-title {
  font-size: 24px;
}

.signup-style {
  color: #0000a0;
  padding-left: 5px;
}

.login-description {
  color: #fff;
}

.underline {
  text-decoration: underline;
}


@media (max-width: 1399px) {
  .login-bg-style {
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .login-form {
    max-width: 500px;
    width: 100%;
  }

  .login-form {
    padding: 15px;
  }

  .text-area-size {
    padding-left: 50px !important;
    padding-right: 50px !important;
    padding-top: 160px;
  }

  .title-style {
    padding-bottom: 50px;
    text-align: left;
    color: #0000a0;
    font-size: 35px;
  }

  .description-text {
    color: #b3b4b8;
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .login-form {
    padding: 0px;
  }

  .login-form form {
    padding: 15px 30px;
  }
}